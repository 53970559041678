/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import {
  ViewContext,
  Card,
  AccountNav,
  Table,
  Button,
  Animate,
  TitleRow,
  useAPI,
  usePermissions,
  Event,
  AuthContext,
} from 'components/lib'
import { LicenseIcon } from 'icons'
import AssignLicense from './assignLicense'
import { useSearchParams } from 'react-router-dom'

export function Users(props) {
  const context = useContext(ViewContext)
  const { user: userContext } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const permissions = usePermissions()
  const data = useAPI('/api/account/users')
  const [users, setUsers] = useState([])

  function invite() {
    context.modal.show(
      {
        title: 'Add User',
        form: {
          email: {
            label: 'Email',
            type: 'text',
            required: true,
          },
          permission: {
            label: 'Permission',
            type: 'select',
            default: 'user',
            options: permissions?.data?.list?.filter(
              (x) => x.value !== 'owner' && x.value !== 'developer' && x.value !== 'manager_core_concept'
            ),
          },
        },
        buttonText: 'Send Invite',
        text: 'To invite more than one user, seperate the emails with a comma',
        url: '/api/invite',
        method: 'POST',
      },
      (form, res) => {
        // add the invited user to the account
        if (res?.length) {
          const state = [...users]

          res.forEach((invite) => {
            if (!state.find((x) => x.id === invite.id)) {
              state.push({
                id: invite.id,
                name: '',
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || 'user',
                status: 'Invited',
                actions: {
                  invite: resendInvite,
                  delete: deleteInvite,
                },
              })
            }
          })

          Event('invited_user')
          setUsers(state)
        }
      }
    )
  }

  function editUser(data, callback) {
    context.modal.show(
      {
        title: 'Update User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          name: {
            label: 'Name',
            type: 'text',
            required: true,
            value: data.name,
            errorMessage: 'Please enter a name',
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
          },
          permission: {
            label: 'Permission',
            type: data.permission === 'owner' ? null : 'select',
            options: permissions?.data?.list?.filter((x) => x.value !== 'owner' && x.value !== 'developer'),
            default: data.permission,
          },
        },
        buttonText: 'Save',
        url: '/api/user',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true)
        if (data?.permission !== 'admin' && res.data?.data.permission === 'admin') {
          Event('admin_role_assigned', {
            user_id: data.id,
            existingRole: data.permission,
            newRole: res.data.data.permission,
          })
        }
        callback(res)
      }
    )
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {},
        buttonText: 'Delete User',
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/user/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(data.name + ' was deleted', 'success', true)
        callback()
      }
    )
  }

  function deleteInvite(data, callback) {
    context.modal.show(
      {
        title: 'Delete Invite',
        form: {},
        buttonText: 'Delete Invite',
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: `/api/invite/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(`${data.email}'s invite was deleted`, 'success', true)
        callback()
      }
    )
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: '/api/invite',
        method: 'post',
        data: { email: data.email },
      })

      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true)
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleOpenLicense = (isOpen, userId) => {
    if (searchParams.get('license') === isOpen) return

    const params = new URLSearchParams(window.location.search)
    if (isOpen) {
      params.set('license', isOpen)
      params.set('userId', userId)
    } else {
      params.delete('license')
    }
    setSearchParams(params)
  }

  useEffect(() => {
    // format the user list
    let list = []

    if (data?.data?.users?.length) {
      list = data.data.users.map((x) => {
        return {
          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          permission: x.permission,
          status: x.verified ? 'Verified' : 'Registered',
        }
      })
    }

    if (data?.data?.invites?.length) {
      data.data.invites.forEach((x) => {
        list.push({
          id: x.id,
          name: '',
          email: x.email,
          date_created: x.date_sent,
          permission: x.permission || 'user',
          status: 'Invited',
        })
      })
    }

    setUsers(list)
  }, [data])

  // attach the per row actions for invites
  if (users.length) {
    users.forEach((u) => {
      if (u.status === 'Invited') {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        }
      }
    })
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <TitleRow title="Manage Users">
          <Button small text="Add User" action={invite} color={props.destructive ? 'brand3' : 'brand3'} />
        </TitleRow>
        <AssignLicense onOpenChange={handleOpenLicense} open={searchParams.get('license') === 'true'} />
        <Card>
          <Table
            search
            className="restrict-width"
            data={users}
            loading={data.loading}
            show={['email', 'name', 'permission', 'status']}
            badge={{
              col: 'status',
              color: 'blue',
              condition: [
                { value: 'verified', color: 'green' },
                { value: 'registered', color: 'blue' },
                { value: 'invited', color: 'orange' },
              ],
            }}
            actions={{
              edit: editUser,
              delete: deleteUser,
              custom: userContext.feature_flags?.FEATURE_FLAG_MULTI_PRODUCT
                ? [
                    {
                      customIcon: <LicenseIcon fill="#4b4f57" />,
                      condition: {
                        col: 'permission',
                        value: 'owner',
                      },
                      action: (e) => {
                        handleOpenLicense(true, e.id)
                      },
                    },
                  ]
                : [],
            }}
          />
        </Card>
      </Animate>
    </Fragment>
  )
}
