import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { sanitizeHTML } from 'utils'
import { Button } from 'components/lib'
import "./styles/document.css"

const HtmlContent = ({ url, searchDocument }) => {
  const [htmlData, setHtmlData] = useState('')
  const [currentMatch, setCurrentMatch] = useState(0)
  const contentRef = useRef(null)

  useEffect(() => {
    const fetchHtmlData = async () => {
      try {
        const response = await fetch(url)
        const unsafeHtml = await response.text()
        setHtmlData(sanitizeHTML(unsafeHtml))
      } catch (error) {
        console.error('Error fetching document content:', error)
      }
    }

    fetchHtmlData()
  }, [url])

  const { highlightedContent, totalMatches } = useMemo(() => {
    if (!searchDocument?.trim()) {
      return { highlightedContent: htmlData, totalMatches: 0 }
    }
    const regex = new RegExp(`(${searchDocument})`, 'gi')
    let count = 0
    const highlighted = htmlData.replace(
      regex,
      (match) => `<mark class="search-result" id="search-result-${++count}">${match}</mark>`
    )
    return { highlightedContent: highlighted, totalMatches: count }
  }, [htmlData, searchDocument])

  useEffect(() => {
    setCurrentMatch(0)
  }, [searchDocument])

  useEffect(() => {
    if (currentMatch > 0 && contentRef.current) {
      const allMatches = contentRef.current.querySelectorAll('.search-result')
      allMatches.forEach((match, index) => {
        match.classList.toggle('bg-orange-300', index + 1 === currentMatch)
      })

      contentRef.current
        .querySelector(`#search-result-${currentMatch}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [currentMatch])

  const handleNavigation = useCallback(
    (direction) => () => {
      setCurrentMatch((prev) =>
        direction === 'next' ? (prev < totalMatches ? prev + 1 : 1) : prev > 1 ? prev - 1 : totalMatches
      )
    },
    [totalMatches]
  )

  return (
    <div>
      {totalMatches > 0 && (
        <div className="absolute z-10 top-4 right-4 bg-white p-2 rounded shadow-md flex items-center space-x-2">
          <Button variant="outline" icon="chevron-left" size="icon" action={handleNavigation('prev')} />
          <span className="text-sm">
            {currentMatch} of {totalMatches}
          </span>
          <Button variant="outline" icon="chevron-right" size="icon" action={handleNavigation('next')} />
        </div>
      )}
      <div ref={contentRef} dangerouslySetInnerHTML={{ __html: highlightedContent }} />
    </div>
  )
}

export default HtmlContent
