import Checkbox from 'components/form/newCheckbox'
import useCallAPI from 'components/hooks/callApi'
import { Button, Drawer } from 'components/lib'
import { AvatarIcon } from 'icons'
import React, { useEffect, useState } from 'react'

const AssignLicense = ({ open, onOpenChange }) => {
  const DrawerContent = ({}) => {
    const [assignProduct] = useCallAPI({
      url: '/api/assign-product',
      method: 'POST',
    })
    const [products, setProducts] = useState([
      {
        price_id: 'price_1Q39u301UDZYrHC9YJB6rVMW',
        name: 'Provisional Tax Calculation',
        description: null,
        price_per_unit: '5.00',
        purchased_quantity: 8,
        assigned_license: 2,
      },
      {
        price_id: 'price_1Q39te01UDZYrHC9XlkilSr2',
        name: 'Depreciation Rate Finder',
        description: null,
        price_per_unit: '5.00',
        purchased_quantity: 8,
        assigned_license: 4,
      },
      {
        price_id: 'price_1Q252B01UDZYrHC9vsA7VIjx',
        name: 'Tax AI Advance',
        description: 'Advance AI Tax Feature',
        price_per_unit: '20.00',
        purchased_quantity: 10,
        assigned_license: 10,
      },
      {
        price_id: 'price_1Q251t01UDZYrHC9fcSbvGqF',
        name: 'Document Questioner',
        description: 'Upload Document and ask question',
        price_per_unit: '10.00',
        purchased_quantity: 10,
        assigned_license: 4,
      },
    ])
    // const productResponse = useAPI('/api/products-list')
    const productResponse = { loading: false }
    // const [products, setProducts] = useState(productResponse.data || [])
    // useEffect(() => {
    //   setProducts(productResponse.data || [])
    // }, [productResponse])

    const [selectedLicenses, setSelectedLicenses] = useState({})

    useEffect(() => {
      const initialSelectedLicenses = products.reduce((acc, product) => {
        acc[product.price_id] = false
        return acc
      }, {})
      setSelectedLicenses(initialSelectedLicenses)
    }, [])

    const handleLicenseToggle = (priceId) => {
      setSelectedLicenses((prev) => {
        const newState = { ...prev }
        newState[priceId] = !newState[priceId]
        return newState
      })

      setProducts((prevProducts) => {
        return prevProducts.map((product) => {
          if (product.price_id === priceId) {
            const newAssignedLicense = selectedLicenses[priceId]
              ? product.assigned_license - 1
              : product.assigned_license + 1
            return { ...product, assigned_license: newAssignedLicense }
          }
          return product
        })
      })
    }

    const handleSave = () => {
      const assignedPriceIds = Object.entries(selectedLicenses)
        .filter(([, isSelected]) => isSelected)
        .map(([priceId]) => priceId)

      const unassignedPriceIds = Object.entries(selectedLicenses)
        .filter(([, isSelected]) => !isSelected)
        .map(([priceId]) => priceId)

      assignProduct({
        requestData: {
          assignedTo: 'user.id',
          assignedPriceIds,
          unassignedPriceIds,
        },
      })
    }

    return (
      <>
        <div className="flex items-center gap-4 mb-4">
          <svg
            width="96"
            height="97"
            viewBox="0 0 96 97"
            fill="none"
            className="mb-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="48" cy="48" r="48" fill="#F6F6F7" />
            <path
              d="M47.7075 51.8914C57.3425 51.8914 65.1532 44.0807 65.1532 34.4457C65.1532 24.8107 57.3425 17 47.7075 17C38.0725 17 30.2618 24.8107 30.2618 34.4457C30.2618 44.0807 38.0725 51.8914 47.7075 51.8914Z"
              fill="#12367F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.0252 81.1794C14.9203 81.8509 15.1469 82.535 15.6379 83.0092C23.9222 91.0753 35.2407 96.0442 47.7091 96.0442C60.1774 96.0442 71.4959 91.0753 79.7802 83.0092C80.2712 82.535 80.4979 81.8509 80.3929 81.1794C79.5032 75.4803 76.8257 70.1673 72.6962 66.0378C67.5594 60.901 60.5887 58.0137 53.3242 58.0137C49.6185 58.0137 45.7996 58.0137 42.0939 58.0137C34.8294 58.0137 27.8587 60.901 22.7219 66.0378C18.5924 70.1673 15.9149 75.4803 15.0252 81.1794Z"
              fill="#27D1FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5024 75.6328C15.8184 77.4206 15.3231 79.2797 15.0252 81.1809C14.9203 81.8523 15.1469 82.5364 15.6379 83.0106C23.9222 91.0767 35.2407 96.0456 47.7091 96.0456C60.1774 96.0456 71.4959 91.0767 79.7802 83.0106C80.2712 82.5364 80.4979 81.8523 80.3929 81.1809C80.095 79.2797 79.5998 77.4206 78.9157 75.6328C70.7154 83.2162 59.7494 87.8494 47.7091 87.8494C35.6687 87.8494 24.7028 83.2162 16.5024 75.6328Z"
              fill="#1974F9"
            />
          </svg>
          <div>
            <h3 className="font-semibold text-2xl text-primary-dark-blue">Jacob Sidford</h3>
            <p className="text-sm font-medium ">Jacob@lawcyborg.com</p>
            <p className="text-sm font-medium ">Admin</p>
            <div className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 mt-5 uppercase rounded">
              Active
            </div>
          </div>
        </div>

        <h4 className="font-semibold text-gray-800 mt-4 mb-2">Licenses</h4>
        {productResponse?.loading ? (
          <div className="flex justify-center items-center h-full">loading...</div>
        ) : (
          <>
            {products.map((product, id) => (
              <div key={product.price_id + id} className="flex items-center justify-between">
                <div className="flex-1">
                  <Checkbox
                    item={{
                      value: product.price_id,
                      label: product.name,
                      checked: selectedLicenses[product.price_id] || false,
                      disabled: product.purchased_quantity - product.assigned_license === 0,
                    }}
                    onChange={() => handleLicenseToggle(product.price_id)}
                  />
                </div>
                <span className="text-sm text-gray-500">
                  {product.purchased_quantity - product.assigned_license} of {product.purchased_quantity} available
                </span>
              </div>
            ))}

            <Button small fullWidth className="mt-5" text="Save" action={handleSave} color={'brand3'} />
          </>
        )}
      </>
    )
  }

  return (
    <Drawer open={open} onOpenChange={onOpenChange} title="Manage License" width="10rem">
      <DrawerContent />
    </Drawer>
  )
}

export default AssignLicense
