/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import { AccountNav, Animate, AuthContext, Button, Icon, Message, TabView, useAPI } from 'components/lib'
import { Fragment, useContext } from 'react'

import { BillingCard } from './card'
import { BillingInvoices } from './invoices'
import Products from './products'
import { BillingPlan } from './plan'

const Messages = require('./messages.json')

const BillingState = ({ subscription }) => {
  return (
    <div className="p-4 bg-white flex w-full justify-between rounded-lg">
      <div className="flex gap-3">
        <Icon size={30} color={'primary-dark-blue'} image={'info'} />

        <div>
          <h2 className="text-xl font-semibold m-0 mb-3">Your Billing Cycle</h2>
          <p className="text-center text-sm">
            {subscription.data.object.current_period_start} to {subscription.data.object.current_period_end}
          </p>
        </div>
      </div>
      <Button
        small
        className="h-fit"
        text="Terms"
        action={() => window.open('https://www.lawcyborg.com/subscriber-terms.pdf', '_blank')}
        color={'blue'}
      />
    </div>
  )
}

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext)
  const featureFlags = context.user.feature_flags

  // fetch subscription
  const subscription = useAPI('/api/account/subscription')
  const isPaid = context.user.plan === 'free' ? false : true
  const labels = ['Products']
  if (isPaid) labels.push('Card', 'Invoices')

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        {!subscription?.loading &&
          subscription?.data?.status !== 'active' &&
          subscription?.data?.status !== 'trialing' && <Message {...Messages[subscription.data?.status]} />}
        {subscription?.data?.object && <BillingState subscription={subscription} />}

        <TabView name="Billing" labels={labels}>
          {featureFlags?.FEATURE_FLAG_MULTI_PRODUCT ? <Products /> : <BillingPlan subscription={subscription} />}

          {isPaid && <BillingCard />}

          {isPaid && <BillingInvoices />}
        </TabView>
      </Animate>
    </Fragment>
  )
}
