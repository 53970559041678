import { useContext } from 'react'
import { AuthContext, AppNav, Header } from 'components/lib'
import { DocAiIcon, DrFinderIcon, CyborgIcon, GeneralAiIcon, ProvTaxIcon, SearchIcon, TaxAiIcon } from 'icons'
import Style from './app.module.scss'
import { HINTS } from 'utils'

export function AppLayout(props) {
  const auth = useContext(AuthContext)
  const featureFlags = auth?.user?.feature_flags

  const isGeneralAIView = props.title === 'General AI'
  const isAIView = props.title === 'Cyborg'
  const isDepView = props.title === 'Depreciation Rate Finder'
  const isSeafarerView = props.title === 'Search Results'
  let bgColorClass = ''
  if (isAIView || isSeafarerView) {
    bgColorClass = Style['app-bg-blue']
  } else if (isDepView) {
    bgColorClass = Style['app-bg-orange']
  }
  return (
    <div className="flex">
      <AppNav
        items={[
          ...(process.env.NODE_ENV === 'production'
            ? [
              { label: 'General AI', icon: <GeneralAiIcon />, link: '/general-ai', hint: HINTS.generalAI },
              { label: 'Tax AI Advanced', icon: <TaxAiIcon />, link: '/tax-ai', hint: HINTS.taxAI },
            ]
            : [{ label: 'Cyborg', icon: <CyborgIcon />, link: '/cyborg/tax', hint: HINTS.cyborg }]),
          {
            label: 'Document Questioner',
            icon: <DocAiIcon />,
            link: '/doc-question',
            hint: HINTS.docQuestion,
          },
          {
            label: 'Depreciation Rate Finder',
            icon: <DrFinderIcon />,
            link: '/dr-finder',
            hint: HINTS.drFinder,
          },
          {
            label: 'Provisional Tax Calculator',
            icon: <ProvTaxIcon />,
            link: '/prov-calc',
            hint: HINTS.provCalc,
          },
          ...(featureFlags?.FEATURE_FLAG_SEAFARER
            ? [
              {
                label: 'Seafarer',
                icon: <SearchIcon fill="#fff" height={15} width={15} />,
                link: '/seafarer/results',
                hint: HINTS.seafarer,
              },
            ]
            : []),
        ]} // Remove undefined entries
        accountActionItems={[
          { label: 'Account', icon: 'user', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
        ]}
      />

      <main className={`${Style.app} ${bgColorClass}`}>
        {props.header && <Header title={props.title} options={props.options}></Header>}
        {<props.children {...props.data} />}
      </main>
    </div>
  )
}
