import { Drawer } from 'components/lib'
import React, { useEffect, useState } from 'react'
import Accordion from 'components/accordion'
import { AddIcon, MinusIcon } from 'icons'

const PurchaseProducts = () => {
  const [data, setData] = useState({
    products: [
      {
        price_id: 'price_1Q53qQ01UDZYrHC99AFM3w3e',
        name: 'Database Navigator',
        description: null,
        price_per_unit: 45,
      },
      {
        price_id: 'price_1Q52No01UDZYrHC9KQpyykz1',
        name: 'Seafarer Module',
        description: null,
        price_per_unit: 10,
      },
    ],
    informationModules: [
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Tax',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Employment',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Personal Injury & Accident Compensation',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Wills & Succession',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Trusts & Equity',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Property',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Commercial & Contract',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Company & Financial Markets',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Bankruptcy & Insolvency',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Building & Construction',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Alternative Dispute Resolution',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Insurance',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'IP & Technology',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Media & Privacy',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Family',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Criminal',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Evidence',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Court Practice & Procedure',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Environment & Resources',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Local Government',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Transport',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Administrative Law',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Fisheries',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Māori Law',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Health Law',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Human Rights & Immigration',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Charities',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Legal Aid',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Social Welfare',
        description: null,
        price_per_unit: 9,
      },
      {
        price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
        name: 'Consumer Rights',
        description: null,
        price_per_unit: 9,
      },
    ],
  })
  const allProducts = [...data.products, ...data.informationModules]
  const [productQuantities, setProductQuantities] = useState({})
  const [isAccordionOpen, setIsAccordionOpen] = useState(true) // New state for accordion

  useEffect(() => {
    const tempProducts = allProducts.reduce((acc, product) => {
      acc[product.name] = 0
      return acc
    }, {})
    setProductQuantities(tempProducts)
  }, [])

  const handleQuantityChange = (name, newValue) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [name]: Math.max(0, parseInt(newValue) || 0),
    }))
  }

  const totalItems = Object.values(productQuantities).reduce((sum, quantity) => sum + quantity, 0)
  const totalValue = allProducts.reduce(
    (sum, product) => sum + parseFloat(product.price_per_unit) * productQuantities[product.name],
    0
  )
  const gst = totalValue > 0 ? totalValue * 0.15 : 0
  const estimatedTotal = totalValue + gst

  const handleContinue = async () => {
    const selectedProducts = allProducts
      .filter((product) => productQuantities[product.name] > 0)
      .map((product) => ({
        plan: product.price_id,
        quantity: productQuantities[product.name],
      }))
    console.log('Products to purchase:', selectedProducts)
  }

  const triggerButton = (
    <button className="cursor-pointer font-sans text-sm h-fit bg-blue-500 hover:bg-blue-600 hover:border-blue-600 relative block text-center font-semibold rounded-xl px-12 py-3 ease-in-out duration-500 text-white">
      Purchase Products
    </button>
  )

  const ProductItem = ({ product }) => (
    <div key={product.name} className="flex justify-between items-center border-b py-1">
      <div>
        <h4 className="font-semibold text-gray-700">{product.name}</h4>
        <p className="text-sm text-gray-500">${product.price_per_unit}</p>
      </div>
      <div className="flex items-center gap-2">
        <input
          type="number"
          className="px-2 py-1 rounded border w-[3rem] text-sm"
          value={productQuantities[product.name]}
          onChange={(e) => handleQuantityChange(product.name, e.target.value)}
        />
        <button
          className="px-3 py-1 bg-[#F6F6F7] h-7 border rounded text-sm"
          onClick={() => handleQuantityChange(product.name, productQuantities[product.name] + 1)}
        >
          <AddIcon />
        </button>
        <button
          className="px-3 py-1 bg-[#F6F6F7] h-7 border rounded text-sm"
          onClick={() => handleQuantityChange(product.name, productQuantities[product.name] - 1)}
        >
          <MinusIcon />
        </button>
      </div>
    </div>
  )

  const DrawerContent = () => (
    <>
      <h3 className="font-medium text-brand-500 pb-2">Products</h3>
      {data.products.map((product) => (
        <ProductItem key={product.name} product={product} />
      ))}
      <Accordion
        className="!shadow-none"
        itemsClassName="!px-0"
        items={[
          {
            title: 'Information Modules',
            content: (
              <div className="max-h-[calc(100vh-30rem)] overflow-y-auto">
                {data.informationModules.map((product) => (
                  <ProductItem key={product.name} product={product} />
                ))}
              </div>
            ),
          },
        ]}
        openIndexes={isAccordionOpen ? [0] : []} // Control open state
        onChange={() => setIsAccordionOpen(!isAccordionOpen)} // Toggle open state
      />
      {/* Order Summary */}
      <div className="border-t pt-4 mt-4">
        <h3 className="font-bold text-gray-700 mb-2">Order Summary</h3>
        <div className="flex justify-between">
          <p className="text-sm">Total Items</p>
          <p className="text-sm">{totalItems}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">Total Value</p>
          <p className="text-sm">${totalValue.toFixed(2)}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">GST</p>
          <p className="text-sm">${gst.toFixed(2)}</p>
        </div>
        <div className="flex justify-between font-semibold text-lg">
          <p>Estimated Total</p>
          <p>${estimatedTotal.toFixed(2)}</p>
        </div>
      </div>

      {/* Continue button */}
      <button className="w-full bg-primary-dark-blue text-white py-2 mt-4" onClick={handleContinue}>
        Continue
      </button>
    </>
  )

  return (
    <Drawer triggerButton={triggerButton} title="Purchase Products" width="28rem">
      <DrawerContent />
    </Drawer>
  )
}
export default PurchaseProducts
