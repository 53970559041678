import Checkbox from 'components/form/newCheckbox'
import React, { useState } from 'react'

const FilterOption = ({ option, value, onFilterChange }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const isNested = typeof value === 'object'

  const handleCheckboxChange = (isChecked) => {
    onFilterChange([], isChecked)
  }

  const handleParentCheckboxChange = (isChecked) => {
    const updateNestedValues = (obj) => {
      const updatedObj = {}
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          updatedObj[key] = updateNestedValues(obj[key])
        } else {
          updatedObj[key] = isChecked
        }
      }
      return updatedObj
    }

    const updatedValue = updateNestedValues(value)
    onFilterChange([], updatedValue)
  }

  const handleExpand = (state) => {
    setIsCollapsed(state)
  }
  const allNestedChecked = (nestedValue) => {
    const checkAllNested = (obj) => {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          if (!checkAllNested(obj[key])) {
            return false
          }
        } else if (!obj[key]) {
          return false
        }
      }
      return true
    }

    return checkAllNested(nestedValue)
  }

  return (
    <div>
      {!isNested ? (
        <Checkbox
          className=""
          item={{
            checked: value,
            label: option,
          }}
          onChange={(e) => handleCheckboxChange(e.target.checked)}
        />
      ) : (
        <>
          <Checkbox
            className=""
            item={{
              checked: allNestedChecked(value),
              label: option,
            }}
            onChange={(e) => handleParentCheckboxChange(e.target.checked)}
            isGroup={true}
            handleExpand={handleExpand}
          />
          {isCollapsed && (
            <div style={{ paddingLeft: isNested ? '20px' : '0' }}>
              {Object.keys(value).map((childOption) => (
                <FilterOption
                  key={childOption}
                  option={childOption}
                  value={value[childOption]}
                  onFilterChange={(optionPath, isChecked) => {
                    onFilterChange([childOption, ...optionPath], isChecked)
                  }}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FilterOption
