import React, { useState } from 'react'
import { Button, DocumentHeader, Search, useAPI } from 'components/lib'
import { Link, useParams } from 'react-router-dom'
import HtmlContent from '../htmlContent'
import classNames from './viewDocument.tailwind'
import { downloadFile } from 'utils'

const ViewDocument = () => {
  const { namespace, documentId } = useParams()
  const [searchDocument, setSearchDocument] = useState('')
  const { data: documentFile } = useAPI(`/api/search/document/${namespace}/${documentId}`)

  return (
    <div className={classNames.container}>
      <div className={classNames.header}>
        <DocumentHeader SearchClassName={'absolute left-1/2 top-0 transform -translate-x-1/2'} />
      </div>
      <div className={classNames.content}>
        <div className="flex justify-between items-center py-4">
          <div className={classNames.breadcrumbs}>
            <Link to="/seafarer">
              <span className={classNames.breadcrumbLink}>Search Result</span>
            </Link>
            / {documentFile?.fullTitle}
          </div>
          <div className={classNames.tools}>
            <Search
              className={classNames.search.wrapper}
              buttonClassName={classNames.search.button}
              callback={(e) => setSearchDocument(e)}
              placeholder="Search..."
              inputClassName={classNames.search.input}
            />
            <Button
              icon="copy"
              color="#12367F"
              size={16}
              action={() => console.log('copied')}
              className={classNames.button}
            />
            <Button
              icon="download"
              color="#12367F"
              size={16}
              action={() => downloadFile(documentFile?.output_html_url, documentFile?.fullTitle || 'document.pdf')}
              className={classNames.button}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 mt-4">
          <div className={classNames.title}>{documentFile?.fullTitle}</div>
          <HtmlContent url={documentFile?.html_url} searchDocument={searchDocument} />
        </div>
      </div>
    </div>
  )
}

export default ViewDocument
