export const namespaces = {
  nz: {
    tax: {
      Legislation: [
        { 'Income Tax Act 2007': 'nz_tax_income_tax_act' },
        { 'Tax Administration Act 1994': 'nz_tax_tax_administration_act' },
        { 'Goods and Services Tax Act 1985': 'nz_tax_goods_and_services_tax_act' },
        { 'Companies Act 1993': 'nz_tax_companies_act' },
      ],
      'Tax Technical Publications': [
        { 'Interpretation Statements': 'nz_tax_interpretation_statements' },
        { 'Interpretation Guidelines': 'nz_tax_interpretation_guidelines' },
        { 'Issue Papers': 'nz_tax_issue_papers' },
        { "Questions We've Been Asked": 'nz_tax_questions_weve_been_asked' },
        { Determinations: 'nz_tax_determinations' },
        { 'Fact Sheets': 'nz_tax_fact_sheets' },
        { 'General Articles': 'nz_tax_general_articles' },
        { 'Operational Guidelines': 'nz_tax_operational_guidelines' },
        { 'Operational Positions': 'nz_tax_operational_positions' },
        { 'Revenue Alerts': 'nz_tax_revenue_alerts' },
        { Rulings: 'nz_tax_rulings' },
        { 'Technical Decision Summaries': 'nz_tax_technical_decision_summaries' },
        { 'Standard Practice Statements': 'nz_tax_standard_practice_statements' },
        { 'Operational Statements': 'nz_tax_operational_statements' },
      ],
      'Tax Policy Publications': [
        { 'Bill Commentaries': 'nz_tax_bill_commentaries' },
        { 'Special Reports': 'nz_tax_special_reports' },
        { 'Officials Reports': 'nz_tax_officials_reports' },
        { 'Information Releases': 'nz_tax_information_releases' },
        { 'Consultation Documents': 'nz_tax_consultation_documents' },
        { 'Regulatory Impact Statements': 'nz_tax_regulatory_import_statements' }, // Overwritten as 'Regulatory Impact Statements'
        { 'Other Policy Publications': 'nz_tax_other_policy_publications' },
      ],
      Cases: [
        { 'Taxation Review Authority': 'nz_tax_taxation_review_authority' },
        { 'High Court Cases': 'nz_tax_high_court' },
        { 'Court of Appeal Cases': 'nz_tax_court_of_appeal' },
        { 'Supreme Court Cases': 'nz_tax_supreme_court' },
      ],
      'Tax Treaties': [{ 'Double Tax Agreements': 'nz_tax_double_tax_agreements' }],
      'IR Procedures': [{ 'Forms and Guides': 'nz_tax_forms_and_guides' }],
    },
  },
}

const createNamespaceObject = (namespaceCategories) => {
  const processNamespaceArray = (namespaceArray) => {
    return namespaceArray.reduce((obj, item) => {
      const [key, value] = Object.entries(item)[0] // Get key/value from item
      return { ...obj, [key]: { namespace: value, checked: false } }
    }, {})
  }

  return Object.entries(namespaceCategories).reduce((categoriesObj, [category, namespaceArray]) => {
    categoriesObj[category] = processNamespaceArray(namespaceArray)
    return categoriesObj
  }, {})
}

export const disclaimers = {
  nz: {
    tax: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: [
          'What is thincap? Does it apply to NZ wholly-owned companies?',
          'What provisions should I look at when considering whether bank fees are interest under the ITA?',
          'Please prepare an email explaining s CB 6A and why it was introduced',
          'When considering FIFs, where should I look in the Act?',
          'How do I work out whether an arrangement is tax avoidance?',
          'Draft a warm email to Emma, explaining the penalties for tax shortfall',
          'What is the section of the TAA which relates to late filing penalties?',
          'Take me through calculating total assessable income',
          'How do I calculate the value of accommodation for tax?',
          "Tell me ten NZ-tax specific jokes and explain why they're funny",
          'When should I worry about the FIF rules? Tell me in rhyme',
          'What does "money" mean in s EW 3 / the FA rules?',
          'Who must pay provisional tax again? Does a loss-making startup?',
          'What is the point of the controlled foreign company rules?',
          'Should a small company worry about the hybrid rules?',
          'Draft an email to Christopher explaining our withholding tax regime',
          'Explain s EC 45 and the bloodstock valuation rules',
          'When is a motor vehicle a fringe benefit? when is public transport?',
        ],
        isClickable: true,
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          'Has extensive knowledge of New Zealand tax law.',
          'Allows for follow-up questions and explanations, including the ability to explain in diagram form.',
          'Guides the user to further reading to ensure accuracy.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'The user has a responsibility to check the sources and accuracy.',
          'Currently is limited as to the number of sources it can consider.',
          'May struggle to complete complex calculations accurately.',
        ],
      },
    ],
    general: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: [
          "How can I get rid of a blank page on word that won't disappear?",
          'Write me an excel formula that finds the ten largest numbers in a list',
          'Draft an email for my boss, Mary, asking for leave tomorrow',
          'What is the word for when two things have a contrasting effect?',
          'Could you code an Excel macro to remove duplicates from a sheet? ',
          'What are some synonyms for the word “furthermore”?',
          'How do I politely say to a client that I’m unable to help due to a conflict?',
          'What is an exciting way to tell people that I’m fun?',
          'Please give me some pub quiz team names using the name “Paul”',
          'How can I export a whole notebook from OneNote?',
          'How do I change my status on Teams? Also suggest a funny status',
          'How do I use mail merge on Outlook? Do I need word?',
          'Write me a plan to send my bookmarks to my colleague',
          'How do I access the master slides on PowerPoint?',
          'What happens if I don’t get enough CPD hours in a year?',
          'Write me a nice email to the Law Cyborg team thanking them',
          'Give me five puns using the same “Sarah” for my colleagues',
          'Explain the blockchain to me like I am 5 years old',
        ],
        isClickable: true,
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          'Has extensive general knowledge, including of Word and Excel.',
          'Allows for follow-up questions and explanations.',
          'Can reformat data or rewrite emails in perfect UK English.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'The user has a responsibility to check the accuracy of any output.',
          'Currently has a limited input capability.',
          'May struggle to complete complex calculations accurately.',
        ],
      },
    ],
    depreciation: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: ['Mattress', 'XPS 15', '收银机'],
        isClickable: true,
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          "Classifies assets in accordance with Inland Revenue's guidelines.",
          'Finds rates based on meaning.',
          'Recognises industry-specific asset descriptions.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'The user has a responsibility to check the accuracy of any output.',
          'Currently has trouble identifying when default rates should be used.',
          'This tool is not conversational.',
        ],
      },
    ],
    docUpload: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: [
          'Please critique this advice. Tell me what can be improved.',
          'What does the annual report say about tax governance?',
          "How do previous years' EBITDA compare?",
        ],
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          'Allows for the secure upload and questioning of documents.',
          'Can create lists, summaries or compare parts of documents.',
          'Has general knowledge and reasoning capabilities.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'This AI does not have tax technical knowledge yet.',
          'This AI does not provide citations yet.',
          'If chat history is disabled, documents are deleted as soon as you leave the page.',
        ],
      },
    ],
  },
}

export const nzTax = {
  namespaces: {
    Auto: { checked: true, namespace: '' },
    ...createNamespaceObject(namespaces.nz.tax),
  },
  disclaimers: disclaimers.nz.tax,
  chatType: 'aiTax',
  usesChatHistory: true,
}

export const nzTaxDepreciation = {
  namespaces: {
    'Tax Depreciation': {
      checked: true,
      namespace: 'nz_tax_depreciation_class_defaults',
    },
  },
  disclaimers: disclaimers.nz.depreciation,
  chatType: 'aiDRFinder',
  usesChatHistory: true,
}

export const general = {
  namespaces: {
    general: {
      checked: false,
      namespace: 'general',
    },
  },
  disclaimers: disclaimers.nz.general,
  chatType: 'aiGeneral',
  usesChatHistory: true,
}

export const docUpload = {
  disclaimers: disclaimers.nz.docUpload,
  chatType: 'aiDocUpload',
  usesChatHistory: true,
}
