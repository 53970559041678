import { Fragment, useEffect, useState, useContext } from 'react'
import React from 'react'
import { CopyIcon } from 'components/icon/copyIcon.js'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { SettingsContext } from 'app/settings'
import { MessageFeedbackButton } from 'components/feedback/messageFeedbackButton'
import ResponseBody from './ResponseBody'
import { useAudio } from '../useAudio.js'
import { Icon } from 'components/lib'
import Style from './conversationMessage.tailwind.js'
import { TTSLoadingIcon } from 'icons'
import { usePlayingContext } from '../usePlayingContext'
export const ConversationResponse = React.memo(
  ({ response, sources, accuracy, exchanges, streaming, audioKey, responseIndex }) => {
    const settingsContext = useContext(SettingsContext)
    const hideAnswerFeedback = settingsContext?.settings?.answer_feedback === 1 ? true : false || false
    const [docLinks, setDocLinks] = useState([])
    const [copyText, setCopyText] = useState('')
    const { isAudioLoading, audioBlob, audioIndex, togglePlay, handleSynthesizeAndPlayAudio } = useAudio()
    const { playingIndex, setPlayingIndex, isGlobalLoading } = usePlayingContext()
    const isThisPlaying = playingIndex === responseIndex

    useEffect(() => {
      if (sources) {
        const titles = sources.map((source) => source.title)
        const assistantLinks = sources.map((source) => source.link)
        const links = titles.map((title, index) => {
          // Get the page number and the title separately
          const match = title.includes(' at ') ? title.match(/(.+) at (\d+)$/) : [title, title, '']

          // Create a default URL for all titles
          const urlTitle = title.replace(/ at (\d+)$/g, '').replace(/\//g, '-')
          const formattedUrlTitle = encodeURIComponent(urlTitle) + '.pdf'
          let url = `https://docs.lawcyborg.com/source/${formattedUrlTitle}`

          // If the title includes a page number, include the page number in the URL
          if (match) {
            const [, , pageNumber] = match
            url += `#page=${pageNumber}`
          }

          if (assistantLinks[index]) {
            url = assistantLinks[index]
          }

          return url
        })

        setDocLinks(links)
      }
    }, [sources])

    const formatTitles = (sources) => {
      const titles = sources.map((source) => source.title)
      if (titles.length === 0) return ''

      // Apply italic formatting to case titles
      let formattedTitles = titles.map((title, titleIndex) => {
        if (title.includes('[')) {
          // Identify case names based on the presence of square brackets
          const bracketIndex = title.indexOf('[')
          const caseName = title.slice(0, bracketIndex)
          const citation = title.slice(bracketIndex)
          return `<a href="${docLinks[titleIndex]}" target="_blank" class="transition-colors duration-300 ease-in-out hover:text-blue-500"><i>${caseName}</i>${citation}</a>`
        } else {
          return `<a href="${docLinks[titleIndex]}" target="_blank" class="transition-colors duration-300 ease-in-out hover:text-blue-500">${title}</a>`
        }
      })

      formattedTitles = [...new Set(formattedTitles)]

      if (formattedTitles.length === 1) return formattedTitles[0]

      if (formattedTitles.length === 2) return formattedTitles.join(' and ')

      const lastTitle = formattedTitles.pop()
      return `${formattedTitles.join(', ')}, and ${lastTitle}`
    }

    const renderIcon = (image, size = 24, color = 'grey', disableHover = false) => {
      const iconClass = disableHover ? Style.noHoverIcon : Style.icon
      return <Icon className={iconClass} image={image} color={color} size={size} />
    }

    const handlePlayPause = async () => {
      if (isGlobalLoading) {
        console.log('Cannot play text while audio is loading')
        return
      }
      if (streaming) {
        console.log('Cannot play text while response is loading')
        return
      }
      if (isThisPlaying) {
        togglePlay(audioKey)
        setPlayingIndex(null)
      } else {
        setPlayingIndex(responseIndex)
        if (audioBlob && audioIndex === audioKey) {
          togglePlay(audioKey)
        } else {
          handleSynthesizeAndPlayAudio(response, audioKey)
        }
      }
    }

    useEffect(() => {
      if (isThisPlaying) {
        togglePlay(audioKey, true)
      }
    }, [isThisPlaying])

    return !response ? null : (
      <Fragment>
        <div
          className={`message overflow-visible whitespace-normal mx-3 px-4 text-left text-black/90 drop-shadow-lg border-x border-t border-black/5 ${`bg-white self-start border-x border-b text-black/80 py-2 ${
            sources?.length > 0 ? '' : 'rounded-b-md mb-8'
          }`}`}
          // LV Tailwind does not support overflow-wrap: anywhere, so specifying it here
          style={{ 'overflow-wrap': 'anywhere' }}
        >
          <div className="message__text relative">
            <>
              <div>
                {isAudioLoading ? (
                  <div className={Style.iconWrapper}>
                    <TTSLoadingIcon />
                  </div>
                ) : streaming ? (
                  <Tippy content="Streaming..." animation="shift-away" arrow={roundArrow} inertia={true}>
                    <div className="inline top-0 float-right">{renderIcon('volume-2', 24, 'grey', true)}</div>
                  </Tippy>
                ) : (
                  <>
                    {isThisPlaying ? (
                      <Tippy content="Pause audio" animation="shift-away" arrow={roundArrow} inertia={true}>
                        <div className={Style.iconWrapper} onClick={() => handlePlayPause()}>
                          {renderIcon('pause-circle', 24)}
                        </div>
                      </Tippy>
                    ) : isGlobalLoading ? (
                      <Tippy content="Audio is loading..." animation="shift-away" arrow={roundArrow} inertia={true}>
                        <div className="inline top-0 float-right">{renderIcon('volume-2', 24, 'grey', true)}</div>
                      </Tippy>
                    ) : (
                      <Tippy content="Play audio" animation="shift-away" arrow={roundArrow} inertia={true}>
                        <div className={Style.iconWrapper} onClick={() => handlePlayPause()}>
                          {renderIcon('volume-2', 24)}
                        </div>
                      </Tippy>
                    )}
                  </>
                )}
              </div>

              <CopyIcon size={24} color="grey" content={copyText} />
              {!hideAnswerFeedback && <MessageFeedbackButton chatHistory={exchanges} />}
              {accuracy ? (
                <Tippy content="Estimated accuracy" animation="shift-away" arrow={roundArrow} inertia={true}>
                  <div
                    className="inline top-0 float-right ml-2 hover:text-black/80 cursor-pointer select-none"
                    style={{ color: '#ccc', cursor: 'text' }}
                  >
                    {`${accuracy}%`}
                  </div>
                </Tippy>
              ) : (
                // Placeholder with the same dimensions and styling as the message.accuracy element
                <div
                  className="inline top-0 float-right ml-2"
                  style={{
                    color: 'transparent',
                    cursor: 'text',
                    width: '40px',
                  }} // Adjust width as needed
                >
                  100%
                </div>
              )}
            </>

            <ResponseBody message={response} setCopyText={setCopyText} className="response-body" />
          </div>
        </div>

        {sources && sources.length > 0 && (
          <pre className="bg-white mb-8 mx-3 rounded-b-md py-2 px-4 text-left text-black/80 drop-shadow-lg border-x border-b border-black/5 animate-down text-sm">
            <div className="overflow-auto whitespace-pre-wrap">
              <span className="font-semibold">Sources: </span>
              <span
                className="message__sources"
                dangerouslySetInnerHTML={{
                  __html: formatTitles(sources),
                }}
              ></span>
            </div>
          </pre>
        )}
      </Fragment>
    )
  }
)
