import { useState, useEffect } from 'react'
import { Button } from 'components/lib'
import { SearchIcon, TargetIcon } from 'icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'

const SearchForm = ({ handleSearchChange, clearSearch, handleSubmit, className, autoFocus }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const isTargetMode = searchParams.get('targetMode') === 'true'

  const [searchText, setSearchText] = useState(searchParams.get('searchKey') || '')

  useEffect(() => {
    setSearchText(searchParams.get('searchKey') || '')
  }, [searchParams])

  const toggleTargetMode = () => {
    setSearchParams((prev) => {
      if (prev.has('targetMode')) {
        prev.delete('targetMode')
      } else {
        prev.set('targetMode', 'true')
      }
      return prev
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const currentParams = new URLSearchParams(searchParams)
    currentParams.set('searchKey', searchText)
    const newUrl = `/seafarer/results?${currentParams.toString()}`
    navigate(newUrl)
    if (handleSubmit) handleSubmit(searchText)
  }

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value)
    if (handleSearchChange) handleSearchChange(e)
  }

  const handleClearSearch = (e) => {
    setSearchText('')
    if (clearSearch) clearSearch(e)

    const input = document.getElementById('searchInput')
    if (input) {
      input.value = ''
      input.focus()
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(e)
    }
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      noValidate
      className={
        'group flex items-center bg-white p-4 rounded-full my-4 transition-all duration-300 shadow-[0px_0px_6px_1px_#0000000D] focus-within:border-[rgba(59,130,246,0.5)] focus-within:shadow-[0_0_0_2px_rgba(59,130,246,0.5)] max-w-[800px] w-full mx-auto ' +
        className
      }
    >
      <input
        type="text"
        autoFocus={autoFocus}
        id="searchInput"
        value={searchText}
        onChange={handleSearchInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search by keyword, description, or simply ask a question..."
        className="flex-grow appearance-none bg-transparent border-none outline-none text-base px-2 placeholder-gray-400"
        autoComplete="off"
      />
      <div className="flex items-center">
        {searchText && <Button icon={'x'} size={20} color={'dark'} className="mr-2" action={handleClearSearch} />}
        <div className="w-px h-6 bg-gray-300 mx-2 hidden sm:block"></div>
        <div className="flex gap-2 px-2">
          {/* FIXME: uncomment when we have a target mode */}
          {/* <Tippy content={'Target Mode'} arrow={roundArrow} animation="shift-away" inertia={true}>
            <button type="button" onClick={toggleTargetMode}>
              <TargetIcon fill={isTargetMode ? undefined : '#6c6c6c'} />
            </button>
          </Tippy> */}
          <button type="submit" className=" " id="inputField">
            <SearchIcon />
          </button>
        </div>
      </div>
    </form>
  )
}

export default SearchForm
