import React, { useState } from 'react'
import { formatDate, highlightText, truncateContent } from 'utils/helper'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/sheet/sheet'
import { Link } from 'react-router-dom'
import { CrossIcon, RedirectIcon } from 'icons'
import HtmlContent from './htmlContent'
import { useAPI } from 'components/lib'

const DocumentPreview = ({ namespace, documentId }) => {
  const { data: documentFile } = useAPI(`/api/search/document/${namespace}/${documentId}`)
  return (
    <div className="max-h-full overflow-y-scroll p-4">
      <h2 className="text-lg text-primary-dark pb-2">{documentFile?.fullTitle}</h2>
      <HtmlContent url={documentFile?.html_url} />
    </div>
  )
}

const ResultItem = ({
  id,
  fullTitle,
  superType,
  documentType,
  last_update,
  text,
  highlight,
  namespace,
  documentId,
}) => {
  const [expanded, setExpanded] = useState(false)
  const maxContentLength = 200

  return (
    <div className="p-4" key={id} id="result-item">
      <Sheet>
        <SheetTrigger>
          <p className="text-[#0C254D] text-left font-bold hover:underline text-base hover:text-[#12367F]">
            {fullTitle}
          </p>
        </SheetTrigger>
        <SheetContent className="bg-white">
          <SheetHeader className=" border-b p-4 border-gray-200">
            <SheetTitle className="flex w-full justify-between items-center">
              <Link
                to={`/document/${namespace}/${documentId}`}
                className="text-primary-dark-blue text-left font-bold  w-[calc(100%-1.5rem)] hover:underline text-base hover:text-primary-dark inline-flex items-center gap-2"
              >
                <p className="truncate max-w-[90%]">{fullTitle}</p>
                <RedirectIcon fill="currentColor" width={14} height={14} />
              </Link>
              <SheetClose className="text-gray-400 hover:text-primary-dark-blue">
                <span className="sr-only">Close</span>
                <CrossIcon />
              </SheetClose>
            </SheetTitle>
          </SheetHeader>
          <SheetDescription className="h-full">
            <DocumentPreview namespace={namespace} documentId={documentId} />
          </SheetDescription>
        </SheetContent>

        <div className="">
          <p className="text-xs text-[#475569] py-2">
            {superType}, {documentType}, <span className="date">{formatDate(last_update, 'DD MMMM YYYY')}</span>
          </p>
        </div>
        <p className="text-[#0C254D] text-sm pb-2">
          {expanded ? highlightText(text, highlight) : truncateContent(text, maxContentLength)}
          {text.length > maxContentLength && (
            <button onClick={() => setExpanded((prev) => !prev)} className="text-[#12367F] text-sm underline ml-2">
              {expanded ? 'Show less' : 'View more'}
            </button>
          )}
        </p>
        <p className="text-[#6C7787] text-sm line-clamp-2">{highlightText(text, highlight)}</p>
      </Sheet>
    </div>
  )
}

export default ResultItem
