import brand from './assets/brand.svg'
import SearchForm from './searchForm'

const SearchTool = () => {
  return (
    <div className="flex h-[calc(100%-10rem)]">
      <div className="h-fit m-auto w-full px-4 sm:px-6 md:px-8">
        <div className="mx-auto w-fit mb-16">
          <img src={brand} alt="lawcyborg" className="" />
        </div>
        <SearchForm autoFocus />
        <span className="text-sm text-[#475569] block text-center mt-4">
        The Taxation (Budget Measures) Bill, assented on 4 June 2024, adjusts the personal income tax thresholds.
        </span>
      </div>
    </div>
  )
}

export default SearchTool
