import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Portal,
} from '@radix-ui/react-dropdown-menu'
import { NavLink, useLocation } from 'react-router-dom'
import Style from './header.tailwind.js'
import styled from './header.module.scss'
import { DownIcon } from 'icons/down.icon.jsx'
import { useRef, useState, useEffect } from 'react'

export function Header(props) {
  const [dropdownWidth, setDropdownWidth] = useState('100%')
  const headerRef = useRef(null)
  const location = useLocation()
  useEffect(() => {
    if (headerRef.current) {
      setDropdownWidth(`${headerRef.current.offsetWidth}px`)
    }
  }, [])

  return (
    <header
      ref={headerRef}
      className={styled.header + ' [data-radix-popper-content-wrapper]:w-full ' + Style.header}
      style={{ '--dropdown-width': dropdownWidth }}
    >
      <div className={styled.left} />

      {props.options?.length > 0 ? (
        <DropdownMenu className="w-full">
          <DropdownMenuTrigger className="avatar-btn w-full">
            {props.title && (
              <h1 className={Style.title}>
                {props.title}
                <span className="px-2 py-1 text-sm font-medium bg-[#0C254D20] rounded">
                  {
                    props.options.find(
                      (item) =>
                        item.link === location.pathname || (location.pathname === '/' && item.link === '/cyborg/tax')
                    )?.label
                  }
                </span>{' '}
                <DownIcon />
              </h1>
            )}
          </DropdownMenuTrigger>
          <Portal className="w-full" container={headerRef.current}>
            <DropdownMenuContent
              sideOffset={20}
              align={'center'}
              className={`bg-white p-2 w-[var(--dropdown-width)] font-normal text-sm border border-[#47556933] rounded-2xl`}
              style={{ width: 'var(--dropdown-width)' }}
            >
              {props.options?.map((item) => {
                if (item.link) {
                  return (
                    <DropdownMenuItem asChild key={item.label} className="focus-visible:outline-none">
                      <NavLink
                        className={
                          'flex gap-3 items-center p-2 hover:bg-[#0C254D10] mb-1 rounded' +
                          (item.link === location.pathname ? ' bg-[#0C254D15]' : '')
                        }
                        to={item.link}
                      >
                        {item.icon} {item.label}
                      </NavLink>
                    </DropdownMenuItem>
                  )
                }
              })}
            </DropdownMenuContent>
          </Portal>
        </DropdownMenu>
      ) : (
        props.title && <h1 className={Style.title}>{props.title}</h1>
      )}

      {props.children}
      <div className={styled.right} />
    </header>
  )
}
