import { Animate, Card, Table, TitleRow } from 'components/lib'
import PurchaseProducts from './purchaseProducts'

// Static data for products
const staticProducts = [
  {
    price_id: 'price_1Q53qQ01UDZYrHC99AFM3w3e',
    name: 'Database Navigator',
    description: null,
    price_per_unit: 45,
    purchased_quantity: 2,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52No01UDZYrHC9KQpyykz1',
    name: 'Seafarer Module',
    description: null,
    price_per_unit: 10,
    purchased_quantity: 4,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Tax',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 4,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Employment',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 3,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Personal Injury & Accident Compensation',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Wills & Succession',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Trusts & Equity',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Property',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Commercial & Contract',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Company & Financial Markets',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Bankruptcy & Insolvency',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Building & Construction',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Alternative Dispute Resolution',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Insurance',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'IP & Technology',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Media & Privacy',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Family',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Criminal',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Evidence',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Court Practice & Procedure',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Environment & Resources',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Local Government',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Transport',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Administrative Law',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Fisheries',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Māori Law',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Health Law',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Human Rights & Immigration',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Charities',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Legal Aid',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Social Welfare',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
  {
    price_id: 'price_1Q52NV01UDZYrHC98MlpVJFd',
    name: 'Consumer Rights',
    description: null,
    price_per_unit: 9,
    purchased_quantity: 0,
    assigned_license: 0,
  },
]

export default function Products(props) {
  // Commented out API call
  // const [saveProducts, data, loading, error] = useCallAPI({
  //   url: '/api/buy-product-license',
  //   method: 'POST',
  // })

  // const productResponse = useAPI('/api/products-list') || {}
  // const products = productResponse.data || []

  // Using static data instead
  const products = staticProducts

  return (
    <Card className={props.className}>
      <Animate>
        <TitleRow className="mt-5 px-4" title="Manage Products">
          <PurchaseProducts staticProducts={products} />
        </TitleRow>

        <Card className="border rounded">
          <Table
            className="restrict-width"
            loading={false}
            data={products}
            show={['name', 'assigned_license', 'purchased_quantity']}
            badge={{
              col: 'status',
              color: 'blue',
              condition: [
                { value: 'verified', color: 'green' },
                { value: 'registered', color: 'blue' },
                { value: 'invited', color: 'orange' },
              ],
            }}
          />
        </Card>
      </Animate>
    </Card>
  )
}
