import { useWindowSize } from 'hooks'
import React from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import SearchForm from 'views/searchAI/searchForm'

const DocumentHeader = ({ className, SearchClassName }) => {
  const { width: windowWidth } = useWindowSize()

  return (
    <div className={cn('flex justify-between items-center', className)}>
      {windowWidth > 1280 ? (
        <div className="logoMargin text-xl my-4 font-bold">
          <Link className="text-primary-dark " to="/seafarer">
            SearchAI
          </Link>
        </div>
      ) : null}

      <div className="w-full">
        <SearchForm className={cn('bg-white', SearchClassName)} />
      </div>
    </div>
  )
}

export { DocumentHeader }
