import axios from 'axios'
const Settings = require('settings.json')

export const textToVoice = async (response) => {
  const cleanResponse = removeMarkDown(response)
  const textToSpeechUrl = `${Settings[process.env.NODE_ENV].textToSpeech_url}`

  if (!cleanResponse || cleanResponse.length === 0) {
    return
  }

  try {
    const jwtToken = JSON.parse(localStorage.getItem('user')).token
    const response = await axios.post(
      textToSpeechUrl,
      { text: cleanResponse },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
        responseType: 'json',
      }
    )
    const base64Audio = response.data

    const audioBuffer = Uint8Array.from(atob(base64Audio), (c) => c.charCodeAt(0))
    const blob = new Blob([audioBuffer], { type: 'audio/mpeg' })
    return blob
  } catch (error) {
    console.error('Error when getting the audio', error)
    throw error
  }
}

const removeMarkDown = (text) => {
  return (
    text
      // Remove headers
      .replace(/^#{1,6}\s+/gm, '')
      // Remove bold/italic
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, '$1')
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, '')
      // Remove inline code
      .replace(/`(.+?)`/g, '$1')
      // Remove blockquotes
      .replace(/^>\s+/gm, '')
      // Remove horizontal rules
      .replace(/^(-{3,}|\*{3,})$/gm, '')
      // Remove links
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
      // Remove images
      .replace(/!\[([^\]]+)\]\([^\)]+\)/g, '$1')
      // Remove ordered list numbers
      .replace(/^\d+\.\s+/gm, '')
      // Remove unordered list bullets
      .replace(/^[-*+]\s+/gm, '')
      // Remove task list items
      .replace(/^\s*[-*+]\s+\[[ xX]\]\s+/gm, '')
      // Remove HTML tags
      .replace(/<[^>]*>/g, '')
      // Remove extra whitespace
      .replace(/\s+/g, ' ')
      .trim()
  )
}
