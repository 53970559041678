// import React from 'react'
import ViewDocument from 'views/searchAI/viewDocument'
import SearchResults from 'views/searchAI/searchResults'
import SearchTool from 'views/searchAI/searchTool'

const Routes = [
        {
          path: '/seafarer',
          view: SearchTool,
          layout: 'app',
          permission: 'user',
          featureFlag: 'FEATURE_FLAG_SEAFARER',
          title: 'Seafarer',
        },
        {
          path: '/seafarer/results',
          view: SearchResults,
          layout: 'app',
          permission: 'user',
          featureFlag: 'FEATURE_FLAG_SEAFARER',
          title: 'Search Results',
        },
        {
          path: 'document/:namespace/:documentId',
          view: ViewDocument,
          layout: 'app',
          permission: 'user',
          featureFlag: 'FEATURE_FLAG_SEAFARER',
          title: 'Document',
        },
      ]
export default Routes
