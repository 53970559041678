import { Filter } from 'components/filter'
import SearchForm from './searchForm'
// import Pagination from 'components/pagination/pagination'
import useCallAPI from 'components/hooks/callApi'
import { useWindowSize } from 'hooks'
import { Link, useSearchParams } from 'react-router-dom'
import ResultItem from './individualResults'

const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, results, loading, error] = useCallAPI({
    url: '/api/search',
    method: 'POST',
  })

  const { width: windowWidth } = useWindowSize()

  const searchKey = searchParams.get('searchKey') || ''
  // const currentPage = parseInt(searchParams.get('page') || '1', 10)
  // const totalPages = Math.ceil(results?.totalResults / 10 || 1)

  // useEffect(() => {
  //   if (searchParams.get('searchKey')) {
  //     search()
  //   }
  // }, [searchParams])

  function transformObject(inputObj) {
    if (inputObj == null) {
      return {}
    }

    return Object.fromEntries(
      Object.entries(inputObj)
        .map(([category, subCategories]) => [
          category,
          Object.keys(subCategories || {}).filter((key) => subCategories[key]),
        ])
        .filter(([, trueKeys]) => trueKeys.length)
    )
  }
  function getActiveNamespaces(obj1, obj2, path = '') {
    const namespaces = []

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key

        if (typeof obj2[key] === 'object' && obj2[key] !== null) {
          const nestedNamespaces = getActiveNamespaces(obj1[key] || {}, obj2[key], newPath)
          namespaces.push(...nestedNamespaces)
        } else if (obj1[key]) {
          namespaces.push(obj1[key])
        }
      }
    }

    return namespaces
  }
  // const handlePageChange = (page) => {
  //   setSearchParams((prev) => {
  //     prev.set('page', page.toString())
  //     return prev
  //   })
  // }

  const handleSubmit = (searchInputText) => {
    setSearchParams((prev) => {
      prev.set('searchKey', searchInputText)
      // prev.set('page', '1')
      return prev
    })
    if (searchInputText !== '') {
      search({
        requestData: {
          query: searchInputText,
          sortBy: searchParams.get('sortBy') || 'Most Recent',
          filters: {
            practice_area: searchParams.get('pAreaLabel') ? searchParams.get('pAreaLabel') : 'NZ Tax',
            namespaces: getActiveNamespaces(
              JSON.parse(localStorage.getItem('namespaceMap') || '{}'),
              JSON.parse(searchParams.get('nSpaces') || '{}')['Document Types']
            ),
            categories: transformObject(JSON.parse(searchParams.get('filters'))?.category),
          },
          searchMode: searchParams.get('targetMode') === 'true' ? 'strict' : 'smart',
        },
      })
    }
  }

  return (
    <div className="flex !pt-4 gap-2 h-full overflow-y-auto justify-between">
      {windowWidth > 1280 ? (
        <div className="logoMargin text-xl my-4 font-bold">
          <Link className="text-primary-dark " to="/seafarer">
            Seafarer
          </Link>
        </div>
      ) : null}
      <div className="left flex-1">
        <div className="results ">
          <div className="w-full">
            <SearchForm handleSubmit={handleSubmit} className="bg-white" />
          </div>
          <div className="results px-2 my-4 max-w-[800px] mx-auto">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500"> An error occurred while fetching results.</p>
            ) : (
              results?.data?.length > 0 && (
                <>
                  <span className="resultSummery text-sm text-[#475569]">
                    Showing: 1 to 10 results for ”{searchKey}”
                  </span>
                  {results?.data?.map((result, index) => (
                    <div key={index} className="border-b py-2">
                      <ResultItem id={index} {...result} highlight={searchKey} />
                    </div>
                  ))}
                  {/* TODO: pagination should only show up when there are more than 10 results */}
                  {/* <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  visiblePages={5}
                  onRowsPerPageChange={() => {}}
                  className="custom-pagination py-4"
                /> */}
                </>
              )
            )}
          </div>
        </div>
      </div>
      <div className="right my-4 min-w-64 max-w-72">
        <Filter />
      </div>
    </div>
  )
}

export default SearchResults
