/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Logo, Button, Icon, AuthContext } from 'components/lib'
import './app.scss'
import Avatar from 'components/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import { MenuIcon, NavToggle } from 'icons'
import Tooltip from 'components/tooltip'
import { useWindowSize } from 'hooks'
import { getOS } from 'utils/helper'

const SHORTCUTS =
  process.env.NODE_ENV === 'production'
    ? {
        1: '/general-ai',
        2: '/tax-ai',
        3: '/doc-question',
        4: '/dr-finder',
        5: '/prov-calc',
        6: '/seafarer/results',
      }
    : {
        1: '/cyborg/tax',
        2: '/doc-question',
        3: '/dr-finder',
        4: '/prov-calc',
        5: '/seafarer/results',
      }
export function AppNav(props) {
  const os = getOS()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const context = useContext(AuthContext)
  const { isMobile } = useWindowSize()

  const handleKeyPress = useCallback(
    (event) => {
      const isCommandKey = os === 'macos' ? event.metaKey : event.ctrlKey

      if (isCommandKey && SHORTCUTS[event.key]) {
        event.preventDefault()
        navigate(SHORTCUTS[event.key])
      }
    },
    [navigate, os]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  const handleClickOnMobile = () => {
    if (open) setOpen(false)
  }

  return (
    <>
      <Button
        customIcon={<MenuIcon />}
        color={'dark'}
        size={12}
        className="btn-togglenav"
        action={() => setOpen(!open)}
      />
      <nav className={`sidebar popup ${open ? 'open' : ''} group`}>
        <Logo mark className="logo" />
        <div className="flex flex-col justify-between h-full">
          <Button
            customIcon={isMobile ? <MenuIcon fill={'#fff'} /> : <NavToggle />}
            color={'dark'}
            size={12}
            className={`btn-togglenav ${isMobile ? '' : 'icon-toggle'}`}
            action={() => setOpen(!open)}
          />
          <section className="nav-links">
            {props.items?.map((item) => {
              let hint = os === 'macos' ? item.hint?.mac : item.hint?.nonMac
              if (item.link) {
                return (
                  <Tooltip title={`${item.label} (${hint})`} side="right">
                    <NavLink
                      key={item.label}
                      to={item.link}
                      style={{ width: 100 / props.items.length + '%' }}
                      onClick={handleClickOnMobile}
                    >
                      {item.icon}
                      {item.label && <span className="label">{item.label}</span>}
                    </NavLink>
                  </Tooltip>
                )
              }
            })}
          </section>

          <DropdownMenu className="">
            <DropdownMenuTrigger className="avatar-btn">
              <Tooltip title="Account" side="top">
                <Avatar className="min-w-[30px] inline-block align-middle" />
              </Tooltip>
              <span className="label">{context.user.name}</span>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side={open ? 'top' : 'right'}
              sideOffset={open ? 10 : 30}
              align={open ? 'start' : 'end'}
              className={`bg-white p-2 shadow-md rounded-2xl ${open ? 'w-[17em]' : 'w-[13rem]'}`}
            >
              {props.accountActionItems?.map((item) => {
                if (item.link) {
                  return (
                    <DropdownMenuItem asChild key={item.label} className="focus-visible:outline-none">
                      <NavLink className="flex gap-3 items-center p-2 " to={item.link} onClick={handleClickOnMobile}>
                        <Icon className="icon" image={item.icon} size={open ? 15 : 18} color="dark" /> {item.label}
                      </NavLink>
                    </DropdownMenuItem>
                  )
                }
              })}
              <DropdownMenuSeparator className="h-[1px] bg-black/10  my-2" />
              <div className="flex gap-3 items-center p-2 cursor-pointer" onClick={context.signout} asChild>
                <Icon className="icon" image="log-out" size={open ? 15 : 18} color="dark" />
                <span className="label">Sign Out</span>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </nav>
    </>
  )
}
